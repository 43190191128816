<template>
	<div class="dialog_box">
		<el-dialog :title="form_type +'通讯信息'" :visible.sync="shows" @closed="onclosed" :close-on-click-modal="false"
			:close-on-press-escape="false">
			<div class="form_box">
				<div class="form_flex">
					<div class="form_item2">
						<div class="form_title"> 人员_姓名:</div>
						<div class="form_input4">
							<!-- form.name -->
							<el-select  :disabled="form_type == '编辑'" filterable :filter-method="do_search_user" v-model="select_user_id" placeholder="请选择人员_姓名"
								@change="onchange_user">
								<el-option v-for="item in user_list" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="form_item" v-if="select_user_info.id">
						<div class="form_title"> 员工号:</div>
						<div class="form_input4">
							{{select_user_info.gonghao || ''}}
						</div>
					</div>
					<div class="form_item2" v-if="select_user_info.id">
						<div class="form_title"> 部门与岗位信息:</div>
						<div class="form_input4">
							{{select_user_info.depart_title || ''}}
						</div>
					</div>

					<div class="form_item">
						<div class="form_title"> 人员_证件号码:</div>
						<div class="form_input1">
							<input v-model="form.id_card" type="text" placeholder="请输入人员_证件号码">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 工作邮箱:</div>
						<div class="form_input1">
							<input v-model="form.email" type="text" placeholder="请输入工作邮箱">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 移动电话:</div>
						<div class="form_input1">
							<input v-model="form.mobile" type="text" placeholder="请输入移动电话">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 办公电话:</div>
						<div class="form_input1">
							<input v-model="form.telephone" type="text" placeholder="请输入办公电话">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 现居住地:</div>
						<div class="form_input1">
							<input v-model="form.address" type="text" placeholder="请输入现居住地">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 邮政编码:</div>
						<div class="form_input1">
							<input v-model="form.postal_code" type="text" placeholder="请输入邮政编码">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 户籍地址:</div>
						<div class="form_input1">
							<input v-model="form.native_address" type="text" placeholder="请输入户籍地址">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title"> 通讯地址:</div>
						<div class="form_input1">
							<input v-model="form.tx_address" type="text" placeholder="请输入通讯地址">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">紧急联系人:</div>
						<div class="form_input1">
							<input v-model="form.jj_name" type="text" placeholder="请输入紧急联系人">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">紧急联系人称谓:</div>
						<div class="form_input1">
							<input v-model="form.jj_wc" type="text" placeholder="请输入紧急联系人称谓">
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">紧急联系人电话:</div>
						<div class="form_input1">
							<input v-model="form.jj_mobile" type="text" placeholder="请输入紧急联系人电话">
						</div>
					</div>

				</div>
				<div class="form_btns">
					<div class="btns1" @click="handle_ends()">保存</div>
					<div class="btns2" @click="handle_close()">取消</div>
				</div>

			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {

		},
		data() {
			return {
				form_type: '新增',
		
				shows: false,
				form: {
					"id": 0,
					"user_id": '',
					"name": "",
					"id_card": "",
					"mobile": "",
					"telephone": "",
					"email": "",
					"postal_code": "",
					"address": "",
					"tx_address": "",
					"native_address": "",
					"jj_name": "",
					"jj_wc": "",
					"jj_mobile": ""
				},

				user_list: [],
				select_user_id: '',
				select_user_info: {}
			};
		},
		computed: {
		
		},
		watch: {

		},
		created() {

		},
		methods: {
			onclosed() {
				console.log('表单清空')
				this.select_user_id = '';
				this.select_user_info = {};
				this.form = {
					"id": 0,
					"user_id": '',
					"name": "",
					"id_card": "",
					"mobile": "",
					"telephone": "",
					"email": "",
					"postal_code": "",
					"address": "",
					"tx_address": "",
					"native_address": "",
					"jj_name": "",
					"jj_wc": "",
					"jj_mobile": ""
				}
			},
			//打开
			handle_open(row) {
				this.$log('编辑 row', row)
				this.shows = true;
				if (row) {
					this.form_type = '编辑';

					this.query_tongxunlu_detail(row)
					this.query_user_info(row)
				} else {
					this.form_type = '新增';
	
				}
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			// 通讯录详情
			query_tongxunlu_detail(row) {
				this.$api("getUserAddressBook", {
					id: row.id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.form = {
							"id": data.id || 0,
							"user_id": data.user_id || '',
							"name": data.name || "",
							"id_card": data.id_card || "",
							"mobile": data.mobile || "",
							"telephone": data.telephone || "",
							"email": data.email || "",
							"postal_code": data.postal_code || "",
							"address": data.address || "",
							"tx_address": data.tx_address || "",
							"native_address": data.native_address || "",
							"jj_name": data.jj_name || "",
							"jj_wc": data.jj_wc || "",
							"jj_mobile": data.jj_mobile || ""
						}
					}
				});
			},
			query_user_info(row) {
				if (!row.user_id) {
					return
				}
				
				this.select_user_id  = row.user_id
				this.$api("getUser", {
					id: row.user_id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.user_list = [res.data || {}]
						this.select_user_info = this.user_list.find(v => v.id == row.user_id) || {}
					}
				});
			},


			//确认
			handle_ends() {
				console.log('通讯录编辑/新增')
				if (!this.form.name) {
					return alertErr('请输入姓名')
				}
				if (!this.form.id_card) {
					return alertErr('请输入证件号码')
				}
				// if (!this.form.email) {
				// 	return alertErr('请输入工作邮箱')
				// }
				if (!this.form.mobile) {
					return alertErr('请输入移动电话')
				}
				// if (!this.form.mobile) {
				// 	return alertErr('请输入办公电话')
				// }
				if (!this.form.address) {
					return alertErr('请输入现居住地')
				}
				// if (!this.form.mobile) {
				// 	return alertErr('请输入邮政编码')
				// }
				// if (!this.form.tx_address) {
				// 	return alertErr('请输入通讯地址')
				// }	
				// if (!this.form.native_address) {
				// 	return alertErr('请输入户籍地址')
				// }
				// if (!this.form.jj_name) {
				// 	return alertErr('请输入紧急联系人')
				// }
				// if (!this.form.jj_wc) {
				// 	return alertErr('请输入紧急联系人称谓')
				// }
				// if (!this.form.jj_mobile) {
				// 	return alertErr('请输入紧急联系人电话')
				// }


				// return
				this.loading = true
				// for(var i =0;i<=10;i++) {
				this.$api("editUserAddressBook", {
					...this.form,
					// id: 0,
				}, "post").then((res) => {
					this.loading = false
					alert(res)
					if (res.code == 200) {
						this.shows = false

						if (this.form.id) {
							this.$emit("confirm");
						} else {
							this.$router.push({
								path: '/Newsletter_management_success'
							})
						}
					} else {
						// alertErr(res.msg)
					}
				});
				// }
			},

			do_search_user(value) {
				console.log('搜索用户 value', value)
				this.$api("getUserList", {
					keyword: value,
					gwzt:'在岗'
				}, "get").then((res) => {
					this.$log('搜索用户', res)
					this.user_list = res.data.list || []
				});
			},
			onchange_user(value) {
				console.log('切换用户', value)
				this.select_user_info = this.user_list.find(v => v.id == value) || {}
				this.form.user_id = this.select_user_info.id;
				
				//数据回显
				if(this.form_type == '新增') {
					let item = this.select_user_info;
					this.form.id_card = item.id_card || '';
					this.form.mobile = item.mobile || '';
					this.form.native_address = item.native || '';
				}
			}
		},


	};
</script>

<style scoped lang="less">
	.dialog_box {
		/deep/.el-dialog {
			width: 960px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {

			.form_flex {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				border-bottom: 1px solid #EEEEEE;
				padding-bottom: 23px;

				.form_item {
					width: 50%;
					display: flex;
					align-items: center;
					margin-bottom: 17px;

					.form_title {
						width: 126px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						text-align: right;

						span {
							color: #FC5F03;
							margin-right: 5px;
						}

					}

					.form_input1 {
						width: 313px;
						height: 40px;
						background: #FFFFFF;
						border-radius: 2px 2px 2px 2px;
						border: 1px solid #D4D4D4;
						margin-left: 11px;

						input {
							width: 313px;
							height: 40px;
							padding: 0 10px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #333333;

						}
					}

					.form_input2 {
						width: 313px;
						height: 40px;
						background: #FFFFFF;
						border-radius: 2px 2px 2px 2px;
						margin-left: 11px;
						display: flex;
						align-items: center;
					}

					.form_input3 {
						width: 313px;
						height: 40px;
						background: #FFFFFF;
						border-radius: 2px 2px 2px 2px;
						margin-left: 11px;
						display: flex;
						align-items: center;
					}

					.form_input4 {
						width: 313px;
						height: 40px;
						background: #FFFFFF;
						border-radius: 2px 2px 2px 2px;
						margin-left: 11px;
						display: flex;
						align-items: center;
					}
				}

				.form_item2 {
					width: 100%;
					display: flex;
					align-items: center;
					margin-bottom: 17px;

					.form_title {
						width: 126px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						text-align: right;

						span {
							color: #FC5F03;
							margin-right: 5px;
						}

					}

					.form_input4 {
						width: 759px;
						height: 40px;
						background: #FFFFFF;
						border-radius: 2px 2px 2px 2px;
						margin-left: 11px;
						display: flex;
						align-items: center;

						/deep/.el-select {
							width: 100%;
						}
					}
				}
			}

			.form_btns {
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 18px;

				.btns1 {
					width: 91px;
					height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 38px;
					cursor: pointer;
				}

				.btns2 {
					width: 91px;
					height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;
					text-align: center;
					line-height: 38px;
					cursor: pointer;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
					margin-left: 22px;
				}
			}
		}
	}
</style>