<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">人员_姓名</div>
					<div class="inputs_box">
						<!-- <input type="text" placeholder="请输入" v-model="forms.keyword"> -->
						<el-input clearable v-model="forms.keyword" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">证件号码</div>
					<div class="inputs_box">
						<!-- <input type="text" placeholder="请输入" v-model="forms.id_card"> -->
						<el-input clearable v-model="forms.id_card" placeholder="请输入"></el-input>
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">移动电话</div>
					<div class="inputs_box">
						<!-- <input type="text" placeholder="请输入" v-model="forms.mobile"> -->
						<el-input clearable v-model="forms.mobile" placeholder="请输入"></el-input>
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">工作邮箱</div>
					<div class="inputs_box">
						<!-- <input type="text" placeholder="请输入" v-model="forms.email"> -->
						<el-input clearable v-model="forms.email" placeholder="请输入"></el-input>
					</div>
				</div>


				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box">
						<!-- 		<el-cascader placeholder="请选择" :options="bm_list" collapse-tags :props="props2" :show-all-levels="false"
							v-model="bm_list_ac" clearable></el-cascader> -->

						<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
							@confirm="confirm_bumen_cascader_value" />

					</div>
				</div>

				<div class="search_flex">
					<div class="texts">岗位</div>
					<div class="inputs_box">
						<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" :show-all-levels="false"
							v-model="gw_list_ac" clearable></el-cascader>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">用工类型</div>
					<div class="inputs_box">
						<el-select clearable v-model="forms.yglx" placeholder="请选择">
							<el-option v-for="(item, index) in yglx_list" :key="index" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div>
				<!-- <div class="search_flex">
					<div class="texts">职级</div>
					<div class="inputs_box">
						<el-select clearable v-model="forms.zjmc" placeholder="请选择">
							<el-option v-for="(item, index) in jz_list" :key="index" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div> -->

				<div class="search_flex">
					<div class="btn_ends" @click="handle_search()">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="handle_xz">
						新增
					</div>
					<!-- 		<div class="btns" @click="handle_bj">
						编辑
					</div>
					<div class="btns" @click="handle_sc">
						删除
					</div> -->
					<div class="btns" @click="handle_dr">
						导入
					</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="524">
					<el-table-column fixed type="selection" width="55"></el-table-column>
					<el-table-column fixed prop="name" label="人员_姓名" width="150"></el-table-column>
					<el-table-column prop="id_card" label="人员_证件号码" width="250"></el-table-column>
					<el-table-column prop="mobile" label="移动电话" width="150"></el-table-column>
					<el-table-column prop="depart_title" label="部门" width="300" :show-overflow-tooltip="false"></el-table-column>
					<el-table-column prop="level6" label="岗位" width="180"></el-table-column>

					<el-table-column prop="telephone" label="办公电话" width="150"></el-table-column>
					<el-table-column prop="address" label="现居住地址" width="400"></el-table-column>
					<el-table-column prop="postal_code" label="邮政编码" width="200"></el-table-column>
					<el-table-column prop="email" label="工作邮箱" width="150"></el-table-column>
					<el-table-column prop="native_address" label="户籍地址" width="400"></el-table-column>
					<el-table-column prop="tx_address" label="通讯地址" width="400"></el-table-column>
					<el-table-column prop="jj_name" label="紧急联系人" width="150"></el-table-column>
					<el-table-column prop="jj_wc" label="紧急联系人称谓" width="200"></el-table-column>
					<el-table-column prop="jj_mobile" label="紧急联系人电话" width="200"></el-table-column>
					<!-- 操作模块   -->
					<!-- fixed="right"  -->
					<el-table-column label="操作" fixed="right" width="100">
						<template slot-scope="scope">
							<el-dropdown @command="handleCommand">
								<span class="el-dropdown-link">
									<div class="btn_img">
										<img src="../../assets/img/employee/caozuo.png" alt="">
									</div>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="{ key: 1, row: scope.row }">编辑</el-dropdown-item>
									<el-dropdown-item :command="{ key: 2, row: scope.row }">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<Newsletter_management_add data-title="新增表单" ref="Newsletter_management_add" @confirm="confirm_add">
		</Newsletter_management_add>
		<Newsletter_management_add_dr data-title="导入" ref="Newsletter_management_add_dr"></Newsletter_management_add_dr>
	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	import Newsletter_management_add from "@/components/Newsletter_management/Newsletter_management_add.vue"; //新增
	import Newsletter_management_add_dr from "@/components/Newsletter_management/Newsletter_management_add_dr.vue"; //新增

	export default {
		components: {
			page,
			Newsletter_management_add,
			Newsletter_management_add_dr,
		},
		data() {
			return {
				bumen_position_info: {
					position: '',
					bumen_titles: '',
				},


				forms: {
					keyword: '',
					email: '',
					mobile: '',
					id_card: '',

					//
					gwzt: '', //岗位状态
					yglx: '', //用工类型
					zjmc: '', //职级
				},
				//

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据

				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围
				item_row: {}, //选中的
				// 岗位状态
				zwzt_list: [{
						title: '在岗',
					},
					{
						title: '离职',
					},
					{
						title: '黑名单',
					},
				],
				props: {
					multiple: false,
					value: 'title',
					label: 'title'
				},
				gw_list: [],
				gw_list_ac:[],
				yglx_list:[],

			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
			this.yglx_list = this.get_vuex_type(11); //用工类型
			this.get_gw_list()

		},
		mounted() {

		},
		methods: {
			reset() {
				this.pages.page = 1
				this.get_list()
			},
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			// 新增
			handle_xz() {
				this.$refs.Newsletter_management_add.handle_open()
			},
			confirm_add() {
				this.reset()
			},

			// 编辑
			handle_bj() {
				this.$refs.Newsletter_management_add.handle_open()
			},
			//删除
			handle_sc() {},
			// 批量导入
			handle_dr() {
				this.$refs.Newsletter_management_add_dr.handle_open()
			},
			// 导出

			//操作回调
			handleCommand(e) {
				this.$log('e', e)
				// console.log(e)
				this.item_row = e.row
				let i = e.key
				switch (i) {
					//编辑
					case 1:
						this.handle_bl_row(e.row)
						break;
						// 删除
					case 2:
						this.handle_sc_row(e.row)
						break;
				}
			},
			//编辑
			handle_bl_row(row) {
				this.$refs.Newsletter_management_add.handle_open(row)
			},
			//删除
			handle_sc_row(row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("delUserAddressBook", {
						id: row.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				}).catch(() => {

				});

			},


			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				let level6=''
				if (this.gw_list_ac.length != 0) {
					level6=this.gw_list_ac[this.gw_list_ac.length-1]
				}
				this.$api("getUserAddressBookList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					level6:level6,
					depart_title: this.bumen_position_info.bumen_titles || ''
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},



			confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
				this.$log('勾选部门数据', value)
				let id_arr = value;
				let departIds = id_arr.join(',')
				let departItems = JSON.stringify(value)

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					position: departIds,
					bumen_titles: bumen_titles
				}



				//岗位
				this.position_list = this.cate_flat_list.filter(v => v.parent_id == id_arr[id_arr.length - 1])

				this.$log('岗位列表 position_list', this.position_list)
			},
			//获取岗位
		get_gw_list () {
			 

			 // return

			 this.loading = true
			 this.$api("getCategoryAllList", {

			 }, "get").then((res) => {
				 console.log('所有的岗位', res)
				 this.loading = false
				 if (res.code == 200) {
					 let level = 0
					 let list = []
					 let list2 = []
					 let data = res.data
					 data.forEach((e, i) => {
						 if (e.level > level) {
							 level = e.level
						 }
						 if (e.is_gw != 1) {
							 this.$set(e, 'children', [])
						 }
					 })
					 console.log('最高登记', level)


					 for (let i = 1; i <= level; i++) {
						 let level_list = []
						 let level_list2 = []
						 data.forEach((e) => {
							 if (e.level == i) {
								 level_list.push(e)
							 }
							 if (e.level == i && e.is_gw != 1) {
								 level_list2.push(e)
							 }

						 })
						 list.push(level_list)
						 list2.push(level_list2)
					 }
					 console.log('列表1', list)
					 console.log('列表2', list2)

					 // 总列表  最后一位的下标
					 this.gw_list_data(list, list.length - 1)

					 this.$log('梓豪部门数据', list2)


				 } else {
					 alertErr(res.msg)
				 }
			 });
		 },
		 //处理岗位列表
		 gw_list_data(lists, indexs) {
			 let index1 = indexs //当前下标
			 let index2 = indexs - 1 //上级下标
			 let list = lists
			 list[index1].forEach((e) => {
				 list[index2].forEach((v) => {
					 if (v.id == e.parent_id) {
						 v.children.push(e)
					 }
				 })
			 })
			 list[index2].forEach((e) => {
				 if (e.is_gw == 1) {
					 this.$delete(e, 'children')
				 }
			 })
			 if (index2 == 0) {
				 console.log('最后的数据', list)
				 this.gw_list = list[0]
				 return
			 }
			 this.gw_list_data(list, index2)
		 },

		},


		


	};
</script>

<style scoped lang="less">
	.page_box {
		// min-height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 9px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 30px;
				padding-right: 0;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					margin-right: 48px;
					margin-bottom: 20px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
						width: 100px;
					}

					.inputs_box {
						width: 230px;

						input {
							width: 230px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}

						/deep/.el-select {
							width: 230px;

						}
						/deep/.el-cascader{
							width: 230px;
						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}

				.search_flex:nth-child(5n) {
					// margin-right: 0;
				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
						margin-left: 16px;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				// height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>